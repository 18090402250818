<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Video detail</h1>
        </div>
        <div class="title-tabs mt-3">Video Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6">
                <InputText
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  name="name"
                  isRequired
                  :disabled="isDisable"
                  v-model="form.video.name"
                  :v="$v.form.video.name"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  textFloat="Sort Order"
                  placeholder="Sort Order"
                  type="number"
                  isRequired
                  :disabled="isDisable"
                  v-model="form.video.sort_order"
                  :v="$v.form.video.sort_order"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <div class="d-flex align-items-center">
                      <span class="label">
                        Video Type <span class="text-danger">*</span></span
                      >
                    </div>
                    <InputSelect
                      title=""
                      name="Video Type"
                      isRequired
                      v-model="form.video_type"
                      v-bind:options="videoTypeList"
                      valueField="value"
                      textField="name"
                      @onDataChange="handleChangeVideoType"
                      :disabled="isDisable"
                    />
                  </b-col>
                  <b-col
                    md="12"
                    v-if="form.video_type === 'video_upload'"
                    class="mt-3"
                  >
                    <UploadFile
                      textFloat="Video"
                      placeholder="Please Choose File"
                      :fileName="form.video.media_url"
                      v-model="form.video.media_url"
                      format="video"
                      text="*Please upload only .mp4 less than 150 MB"
                      isRequired
                      :accept="acceptTypeVideo"
                      v-on:onFileChange="onImageChange"
                      :v="$v.form.video.media_url"
                      :disabled="isDisable"
                    />
                    <PreviewBox
                      v-on:delete="deleteVideo"
                      :disabled="isDisable"
                      :showPreviewType="showPreviewType"
                      :showPreview="showVideo"
                      :isLoadingImage="isLoadingImage"
                      :ratioType="2"
                    />
                  </b-col>
                  <b-col md="12" v-else class="mt-3">
                    <InputText
                      textFloat="Link url"
                      placeholder="Link url"
                      type="text"
                      isRequired
                      :disabled="isDisable"
                      v-model="form.video.media_url"
                      :v="$v.form.video.media_url"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" class="mt-3 mt-sm-0">
                <b-row>
                  <b-col md="12">
                    <UploadFile
                      textFloat="Cover Image"
                      placeholder="Please Choose File"
                      format="all"
                      :fileName="form.video.image_url"
                      v-model="form.video.image_url"
                      name="thumbnail"
                      text="*Please upload only file .png, .jpg"
                      isRequired
                      :disabled="isDisable"
                      :accept="acceptTypeImage"
                      :v="$v.form.video.image_url"
                      v-on:onFileChange="onImageChange"
                    />
                    <PreviewBox
                      v-on:delete="deleteImage"
                      :disabled="isDisable"
                      :showPreviewType="0"
                      :showPreview="showPreview"
                      :isLoadingImage="isLoadingImage"
                      :ratioType="2"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md="6">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Parent Category <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="Parent Category"
                  isRequired
                  v-model="form.parent_category"
                  v-bind:options="mainCategoryList"
                  valueField="id"
                  textField="name"
                  @onDataChange="handleChangeParentCategory"
                  :disabled="isDisable"
                />
              </b-col>
              <b-col md="6">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Subcategory <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="Subcategory"
                  isRequired
                  :noPleaseSelect="noPleaseSelect"
                  v-model="form.video.beauty_school_sub_category_id"
                  v-bind:options="subCategoryList"
                  valueField="id"
                  textField="name"
                  @onDataChange="handleChangeSubCategory"
                  :disabled="isDisable"
                  :v="checkrequiredCategoryID"
                />
              </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.video.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.video.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="checkForm(0)" routePath="/video" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import UploadFile from "@/components/inputs/UploadFile";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  name: "VideoDetails",
  components: {
    InputText,
    InputSelect,
    UploadFile,
    OtherLoading,
    PreviewBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      isDisable: false,
      isLoadingImage: false,
      isLoadingVideo: false,
      showPreviewType: 0,
      showVideo: "",
      showPreview: "",
      acceptTypeImage: "image/jpeg, image/png",
      acceptTypeVideo: "video/mp4",
      typelist: [],
      videoTypeList: [
        {
          name: "Video Upload",
          value: "video_upload",
        },
        {
          name: "Link url",
          value: "link_url",
        },
      ],
      mainCategoryList: [],
      subCategoryList: [],
      noPleaseSelect: true,
      isSuccess: false,
      duration: 0,
      form: {
        video: {
          id: 0,
          name: "",
          sort_order: null,
          media_url: "",
          media_sec: 11,
          image_url: "",
          video_type: 2,
          beauty_school_sub_category_id: 0,
          status: 0,
        },
        video_type: "video_upload",
        parent_category: 1,
      },
      checkrequiredCategoryID: {
        required: null,
      },
    };
  },
  validations: {
    form: {
      video: {
        name: { required },
        sort_order: { required },
        media_url: { required },
        image_url: { required },
      },
    },
  },
  created: async function () {
    if (this.id !== "0") {
      await this.getData();
    }
    this.getMainCategory();
    this.$v.$reset();
  },
  methods: {
    getData: async function () {
      await this.$store.dispatch("ActionGetVideoByID", this.id);
      var data = await this.$store.state.moduleVideo.stateGetVideoByID;
      if (data.result == 1) {
        if (data.detail.video_type === 2) {
          this.form.video_type = "video_upload";
        } else {
          this.form.video_type = "link_url";
        }
        this.showPreviewType = 1;
        this.showVideo = data.detail.media_url;
        this.form.video.beauty_school_sub_category_id = parseInt(
          data.detail.beauty_school_sub_category_id
        );
        this.form.parent_category = parseInt(
          data.detail.beauty_school_category_id
        );
        this.form.video = data.detail;
        this.showPreview = data.detail.image_url;
      }
    },
    async getMainCategory() {
      await this.$store.dispatch("ActionGetVideoMainCategory", this.filter);
      var data = await this.$store.state.moduleVideo.stateGetVideoMainCategory;
      if (data.result == 1) {
        this.mainCategoryList = data.detail;
        if (this.id === 0) {
          this.getSubCategory(data.detail[0].id);
        } else {
          this.getSubCategory(parseInt(this.form.parent_category));
        }
      }
    },
    async getSubCategory(parentID) {
      var setData = {
        beauty_school_category_id: parentID,
      };
      await this.$store.dispatch("ActionGetVideoSubCategory", setData);
      var data = await this.$store.state.moduleVideo.stateGetVideoSubCategory;
      if (data.result == 1) {
        this.subCategoryList = data.detail;
      }
    },
    handleChangeParentCategory(id) {
      this.form.video.beauty_school_sub_category_id = 0;
      this.getSubCategory(id);
    },
    handleChangeSubCategory(id) {
      this.form.video.beauty_school_sub_category_id = id;
    },
    handleChangeVideoType(value) {
      this.form.video_type = value;
      this.form.video.media_url = "";
      if (value === "link_url") {
        this.form.video.video_type = 1;
        this.showVideo = "";
      } else {
        this.form.video.video_type = 2;
      }
    },
    onImageChange(val) {
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        if (val.type === "video/mp4") {
          this.isLoadingVideo = true;
          var vm = this;
          var video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = function () {
            vm.form.video.media_sec = parseInt(video.duration);
          };
          video.src = await reader.result;
          var url = await this.saveImagetoDb(reader.result, true);
          this.isLoadingVideo = false;
          this.isDisable = false;
          this.showVideo = url;
          this.showPreviewType = 1;
          this.form.video.media_url = url;
        } else {
          this.isLoadingImage = true;
          let img = new Image();
          img.onload = () => {
            this.setUpdataImage(reader.result);
          };
          img.src = await reader.result;
        }
      };
    },
    async setUpdataImage(base64) {
      var url = await this.saveImagetoDb(base64, false);
      this.isLoadingImage = false;
      this.isDisable = false;
      this.showPreview = url;
      this.form.video.image_url = url;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.video.image_url = "";
      this.showPreview = null;
    },
    deleteVideo() {
      this.form.video.media_url = "";
      this.form.video.media_sec = 0;
      this.showVideo = null;
    },
    checkForm: async function () {
      if (this.form.video.beauty_school_sub_category_id === 0) {
        this.checkrequiredCategoryID.required = false;
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }
        return;
      } else {
        this.checkrequiredCategoryID.required = true;
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }
      }
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.form.video.sort_order = parseInt(this.form.video.sort_order);

      var data = null;
      if (this.id !== "0") {
        await this.$store.dispatch("ActionUpdateVideo", this.form.video);
        data = await this.$store.state.moduleVideo.stateUpdateVideo;
        if (data.result == 1) {
          this.successAlert().then(() => this.$router.push("/video"));
          this.isDisable = false;
        } else if (data.result == 0) {
          this.errorAlert(data.message);
          this.isDisable = false;
        }
      } else {
        await this.$store.dispatch("ActionCreateVideo", this.form.video);
        data = await this.$store.state.moduleVideo.stateCreateVideo;
        if (data.result == 1) {
          this.successAlert().then(() => this.$router.push("/video"));
          this.isDisable = false;
        } else if (data.result == 0) {
          this.errorAlert(data.message);
          this.isDisable = false;
        }
      }
    },
  },
};
</script>

<style scoped>
:before {
  width: 100%;
  padding-top: 56.25%;
}
.preview-box-image {
  width: 100%;
  padding-bottom: 49.385%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}


</style>
